import React from 'react'
import { ActCategory, ActTypeEnum } from './act.enum'

export const useActionAPIs = (action: 'modify' | 'batchDelete') => {
  const apis = React.useMemo(() => ActTypeActionAPI(action), [action])

  const getActionAPI = React.useCallback(
    (actType: number) => {
      let path
      if (actType === ActTypeEnum.PowerInput) {
        path = apis[actType]
      } else {
        const type = ActCategory(actType)

        path = apis[type] ? apis[type] : apis[actType]
      }

      return path
    },
    [apis]
  )

  return { getActionAPI }
}

const ActTypeActionAPI = (action: 'modify' | 'batchDelete') => {
  return {
    ['type1']: `/{companyCode}/act/type1/activity/${action}`,
    ['type2']: `/{companyCode}/act/type2/activity/${action}`,
    [ActTypeEnum.PowerInput]: `/{companyCode}/act/type2/activity/inputPower/${action}`,
    [ActTypeEnum.Shipment]: `/{companyCode}/act/type3/activity/actGoodsTrans/${action}`,
    [ActTypeEnum.Visitor]: `/{companyCode}/act/type3/activity/actGuestRecord/${action}`,
    [ActTypeEnum.Attendance]: `/{companyCode}/act/type3/activity/actEmployeeAttendance/${action}`,
    [ActTypeEnum.Trip]: `/{companyCode}/act/type3/activity/actEmployeeTravel/${action}`,
    [ActTypeEnum.PurchaseGoods]: `/{companyCode}/act/type4/activity/actPurchaseGoods/${action}`,
    [ActTypeEnum.Assets]: `/{companyCode}/act/type4/activity/actAssetManagement/${action}`,
    [ActTypeEnum.WasteDisposal]: `/{companyCode}/act/type4/activity/actScrap/${action}`,
    [ActTypeEnum.WasteWaterDisposal]: `/{companyCode}/act/type4/activity/actScrapWater/${action}`,
    [ActTypeEnum.WaterUsage]: `/{companyCode}/act/type4/activity/actWaterUsage/${action}`,
    [ActTypeEnum.Investment]: `/{companyCode}/act/type5/activity/actInvestment/${action}`,
    [ActTypeEnum.SoldProduct]: `/{companyCode}/act/type5/activity/actProdUsage/${action}`,
    [ActTypeEnum.SoldProductWaste]: `/{companyCode}/act/type5/activity/actProdTerminated/${action}`,
    [ActTypeEnum.DownstreamLeasedAssets]: `/{companyCode}/act/type5/activity/actAssetLease/${action}`,
    [ActTypeEnum.FinancedEmissions]: `/{companyCode}/act/type5/activity/actFinancing/${action}`,
    [ActTypeEnum.GreenElectricityCert]: `/{companyCode}/greenElectricityCert/${action}`,
    [ActTypeEnum.SelfPower]: `/{companyCode}/selfPower/${action}`,
    [ActTypeEnum.Other]: `/{companyCode}/act/type6/activity/actOthers/${action}`,
  } as { [key: string]: string }
}
